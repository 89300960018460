/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');
require('../../node_modules/select2/dist/css/select2.css');
require('../../node_modules/select2-theme-bootstrap4/dist/select2-bootstrap.css');
require('../../node_modules/sweetalert2/dist/sweetalert2.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
const select2 = require('select2')($);

const Swal = require('sweetalert2');

require('bootstrap');



$(document).ready(function () {
    $('[data-toggle="popover"]').popover();

    $('.select2').select2({
        theme: "bootstrap",
        "placeholder": 'choisir ...',
        allowClear: true,
    });
    
    $('.select2_laboratory').select2({
        theme: "bootstrap",
        "placeholder": 'choisir ...',
        allowClear: true
    });
    
    $('.select2_laboratory_emvo').select2({
        theme: "bootstrap",
        "placeholder": 'choisir ...',
        allowClear: true
    });

    $('.add_laboratory').on('click', function(e){
        e.preventDefault();
    });

    $("form[name=laboratory]").on("submit", function (e) {
        e.preventDefault();
        var $this = $(this);
        var ajax_url = $(this).data('url');

        var data = {};
        $(this).serializeArray().forEach((object) => {
            data[object.name] = object.value;
        });
        // console.log(data);

        $.ajax({
            type: "POST",
            url: ajax_url,
            data: data,
            success: function (response) {
                if (response.create) {
                    $('.modal .close').click();
                    $("form[name=laboratory]").find("input[type=text], textarea").val("");

                    var data = {
                        id: response.item.id,
                        text: response.item.value
                    };

                    var newOption = new Option(data.text, data.id, false, false);
                    $('.select2_laboratory').append(newOption).trigger('change');
                    $('.select2_laboratory').val(response.item.id).change();
                }
            }
        });

        //TODO: ajax call here with data
        //If ajax call fails because server can't decode
        //Think of doing : data = JSON.stringify(data);
        // console.log(JSON.stringify(data));

    })

    $('input[type="file"]').change(function (e) {
        var fileName = e.target.files[0].name;
        $(this).closest('.custom-file').find('.custom-file-label').html(fileName);
    });

    $('.select2_laboratory').on('change', function() { 
        var labId = $(this).val();
        // url = '/laboratoires/adresse/' + labId; // dev
        url = '/exploitant/laboratoires/adresse/' + labId; // prod
        var currentId = $(this).attr('id');
        // console.log(currentId);
        var $currentForm = $(this).closest('form');
        
        $.ajax({
            type: "GET",
            url: url,
            success: function (response) {
                if ( response.success == true ) {
                    var data = response.data;

                    if (currentId == 'contact_invoice_laboratory_2') {
                        $('#contact_invoice_address_2').val(data.adress);
                        $('#contact_invoice_zipcode_2').val(data.zipcode);
                        $('#contact_invoice_city_2').val(data.city);
                        $('#contact_invoice_country_2').val(data.country).trigger('change');

                    } else if (currentId == 'contact_invoice_laboratory') {
                        $('#contact_invoice_address').val(data.adress);
                        $('#contact_invoice_zipcode').val(data.zipcode);
                        $('#contact_invoice_city').val(data.city);
                        $('#contact_invoice_country').val(data.country).trigger('change');
                    } else {   
                        $currentForm.find('textarea[id$=_address]').val(data.adress);
                        $currentForm.find('input[id$=_zipcode]').val(data.zipcode);
                        $currentForm.find('input[id$=_city]').val(data.city);
                        $currentForm.find('select[id$=_country]').val(data.country).trigger('change');
                    }
                }
            }
        });
    });

    $('.select2_laboratory_emvo').on('change', function () {
        var labId = $(this).val();
        // url = '/laboratoires/emvo/adresse/' + labId; // dev
        url = '/exploitant/laboratoires/emvo/adresse/' + labId; // prod

        var $currentForm = $(this).closest('form');
        
        $.ajax({
            type: "GET",
            url: url,
            success: function (response) {
                if ( response.success == true ) {
                    var data = response.data;
                    $currentForm.find('input[id=contact_emvo_obp_name]').val(data.name);
                }
            }
        });
    });

});

// console.log('Hello Webpack Encore! Edit me in assets/js/app.js');


// Colletion form
var $collectionHolder;

// setup an "add a tag" link

$(document).ready(function () {
    $('.has-more').each( function(e) {
        var $addItemButton = $('<button type="button" class="btn btn-success add_item_link text-white"><i class="fa fa-plus"></i> Ajouter</button>');
        var $newLinkLi = $(this).next().append($addItemButton);
        // Get the ul that holds the collection of tags
        $collectionHolder = $(this);
        $collectionHolder.find('li').each(function () {
            addItemFormDeleteLink($(this));
        });


        // add the "add a tag" anchor and li to the tags ul
        $collectionHolder.append($newLinkLi);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find(':input').length);

        $addItemButton.on('click', function (e) {
            // add a new tag form (see next code block)
            addRowForm($collectionHolder, $newLinkLi);
        });
    })

    function addItemFormDeleteLink($formLi) {
        var $deleteItemButton = $('<button type="button" class="btn btn-danger confirm-delete" data-confirm="êtes-vous sûr de vouloir supprimer cet élément ?" >Supprimer</button>');
        $formLi.append($deleteItemButton);

        $deleteItemButton.on('click', function (e) {
            // remove the li for the tag form
            var choice = confirm(this.getAttribute('data-confirm'));
            if(choice) {
                $formLi.fadeOut('1000', function () {
                    $target.remove();
                })
            }
        });
    }

    function addRowForm($collectionHolder, $newLinkLi) {
        // Get the data-prototype explained earlier
        var prototype = $collectionHolder.data('prototype');

        // get the new index
        var index = $collectionHolder.data('index');

        var newForm = prototype;
        // You need this only if you didn't set 'label' => false in your tags field in TaskType
        // Replace '__name__label__' in the prototype's HTML to
        // instead be a number based on how many items we have
        // newForm = newForm.replace(/__name__label__/g, index);

        // Replace '__name__' in the prototype's HTML to
        // instead be a number based on how many items we have
        newForm = newForm.replace(/__name__/g, index);

        // increase the index with one for the next item
        $collectionHolder.data('index', index + 1);

        // Display the form in the page in an li, before the "Add a tag" link li
        var $newFormLi = $('<li></li>').append(newForm);
        $newLinkLi.before($newFormLi);
        addItemFormDeleteLink($newFormLi);
    }

    // function form2form(formA, formB) {
    //     $(':input[name]', formA).each(function () {
    //         $('[name=' + $(this).attr('name') + ']', formB).val($(this).val())
    //     })
    // }

});
